<template>
  <div class="bottom" v-if="$route.name !== 'Call'">
    <ul v-if="currentRouteName == 'Home'">
      <li>
        <router-link to="/about">About Us</router-link>
      </li>
      <li>
        <router-link to="/dashboard">Dashboard</router-link>
      </li>
      <li>
        <router-link to="/join/">Join</router-link>
      </li>
      <li>
        <router-link to="/schedule">Schedule</router-link>
      </li>
    </ul>
    <p
      class="copyrights"
      :style="{ 'margin': moveToLeft }"
    >&copy; {{ getCurrentYear }} VisioRoom.com</p>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      moveToLeft: 0
    };
  },
  computed: {
    getCurrentYear() {
      return new Date().getFullYear()
    },
    currentRouteName() {
      return this.$route.name;
    },
  },

  methods: {
    changeBlockPosition() {
      if (this.$route.name === 'Home') {
        this.moveToLeft = ''
      } else {
        this.moveToLeft = 0 + 'px'
      }
    }
  },
  updated() {
    this.changeBlockPosition()
  },
};
</script>

<style lang="scss">
.bottom {
  position: absolute;
  bottom: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 50%;
  padding-right: 60px;
  .copyrights {
    color: white;
    font-weight: 400;
    margin: 0 70px 0 auto;
     @media (min-width: 1200px) and(max-width:1560px) {
        margin: 0 20px 0 0;
      }
  }
  ul {
    display: flex;
    li {
      margin: 0 30px 0 0;
      list-style: none;
      @media (min-width: 1200px) and (max-width:1560px) {
        margin: 0 25px 0 0;
      }
      a {
        text-decoration: none;
        color: white;
        font-size: 16px;
        line-height: 19px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>