<template>
    <div class="schedule">
        <Leftside />
        <div class="rightside">
            <PageHeader page="Schedule">
                <div class="return">
                    <router-link to="/dashboard">
                        <img src="../assets/icons/arrow-back.svg" alt />
                    </router-link>
                    <p>Schedule a meeting</p>
                
                </div>
            </PageHeader>
            <div class="schedule__form">
                <form action class="schedule--form">
                    <div class="schedule__form-body">
                        <div class="schedule__form-data">
                            <FormInput
                                inputType="text"
                                className="input rounded--top"
                                placeholderText="Meeting Name"
                                inputName="meeting name"
                            />
                            <FormInput
                                inputType="text"
                                className="input"
                                placeholderText="Description of the meeting"
                                inputName="Description of the meeting"
                            />
                            <FormInput
                                inputType="text"
                                className="input rounded--bottom"
                                placeholderText="Guest list"
                                inputName="Guest list"
                                :inputValue="searchValue"
                                @inputValue="searchValue = $event"
                                @showList="switchAddContactsPanel"
                            >
                                <div class="guest__list" v-if="addContacts">
                                    <ul>
                                        <li
                                            v-for="item in filteredList"
                                            :key="item.id"
                                            @click="addUserToScheduleMeeting(item)"
                                        >
                                            <div class="guest__list-avatar">
                                                <div class="avatar__image">
                                                    <img
                                                        :src="require('@/assets/img/' + item.image + '')"
                                                        alt
                                                    />
                                                </div>
                                                <div
                                                    class="status"
                                                    :style='{"background": item.online ? "#26BDB4" : "#EE4266"}'
                                                ></div>
                                            </div>
                                            <div class="guest__list-name">
                                                <h4>{{ item.name }}</h4>
                                                <p>{{ item.email }}</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </FormInput>
                            <div class="selected__users" v-if="selectedUsers.length > 0">
                                <ul class="selected__user-list">
                                    <li v-for="user in selectedUsers" :key="user.id">
                                        <div class="selected__user-avatar">
                                            <div class="avatar__image">
                                                <img
                                                    :src="require('@/assets/img/' + user.image + '')"
                                                    alt
                                                />
                                            </div>
                                            <div
                                                class="status"
                                                :style='{"background": user.online ? "#26BDB4" : "#EE4266"}'
                                            ></div>
                                        </div>
                                        <div class="selected__user-name">
                                            <h4>{{ user.name }}</h4>
                                            <p>{{ user.email }}</p>
                                        </div>
                                        <div
                                            class="remove__user"
                                            @click="removeUserFromSelected(user)"
                                        >
                                            <img src="../assets/icons/remove.svg" alt />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="schedule__form-time">
                            <FormInput
                                inputLength="4"
                                :inputType="timeType"
                                :inputValue="timeFormat"
                                @inputValue="formatTime($event)"
                                className="input rounded--top"
                            />
                            <FormInput
                                inputType="date"
                                className="input rounded--bottom"
                                placeholders="settime"
                            />
                        </div>
                    </div>
                    <div class="schedule__form-actions">
                        <Checkbox checkboxText="I agree all statement in" assignLabel="agree">
                            <router-link to="/terms">&nbsp;Terms & Conditions</router-link>
                        </Checkbox>
                        <Button
                            btnName="Create meeting"
                            btnType="button"
                            :btnClass="[{ disabled: btnDisabled },'btn btn--medium btn--default']"
                        />
                    </div>
                </form>
             
            </div>
        </div>
    </div>
</template>

<script>
import Leftside from "../components/Leftside.vue";
import PageHeader from "../components/PageHeader.vue";
import FormInput from "../components/FormInput.vue";
import Checkbox from "../components/Checkbox.vue";
import Button from "../components/Button.vue";
export default {
    props: {},
    components: { Leftside, PageHeader, FormInput, Checkbox, Button },
    data() {
        return {
            addContacts: false,
            selectedUsers: [],
            searchValue: '',
            timeFormat: '',
            timeType: 'time',
            btnDisabled: true,
            roomID: '',
            guests: [
                {
                    id: 1,
                    image: 'avatar.png',
                    name: 'Aidas Kelly',
                    email: 'aidaskelly@gmail.com',
                    online: true
                },
                {
                    id: 2,
                    image: 'avatar.png',
                    name: 'Alice Gordon',
                    email: 'alicegordon@gmail.com',
                    online: true
                },
                {
                    id: 3,
                    image: 'avatar.png',
                    name: 'Edita Jakubaviciute',
                    email: 'editajakubaviciute@gmail.com',
                    online: true
                },
                {
                    id: 4,
                    image: 'avatar.png',
                    name: 'Max Factor',
                    email: 'maxfactor@gmail.com',
                    online: false
                },
                {
                    id: 5,
                    image: 'avatar.png',
                    name: 'Max Factor',
                    email: 'maxfactor@gmail.com',
                    online: true
                },
                {
                    id: 6,
                    image: 'avatar.png',
                    name: 'Max Factor',
                    email: 'maxfactor@gmail.com',
                    online: false
                },
            ]
        };
    },

    methods: {
        addUserToScheduleMeeting(item) {
            if (this.selectedUsers.includes(item)) return
            this.selectedUsers.push(item)
        },
        removeUserFromSelected(item) {
            this.selectedUsers.splice(this.selectedUsers.indexOf(item), 1)
        },
        switchAddContactsPanel() {
            this.addContacts = !this.addContacts
        },
        formatTime(event) {
            this.timeFormat = event
            console.log(this.timeFormat)
        }

    },
    computed: {
     
        filteredList() {
            return this.guests.filter(guest => {
                return guest.name.toLowerCase().includes(this.searchValue.toLowerCase())
            })
        }
    }
};
</script>

<style lang="scss">
.schedule {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    .rightside {
        padding-left: 70px;
        padding-top: 45px;
        position: relative;
    }
    .return {
        display: flex;
        align-items: center;
        img {
            margin-right: 30px;
        }
        p {
            font-weight: bold;
            font-size: 36px;
            line-height: 43px;
            color: $maindark;
            font-family: $golosBold;
        }
    }
    .form__control {
        max-width: 520px;
    }
}
.guest__list {
    padding: 0 20px;
    position: absolute;
    background: $white;
    z-index: 2;
    width: 100%;
    height: auto;
    overflow: auto;
    left: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    ul {
        list-style: none;
        height: 100%;
        max-height: 340px;
        li {
            cursor: pointer;
            display: flex;
            padding: 18px 0;
            border-bottom: 1px solid $darkgray;
            align-items: center;
            position: relative;
            z-index: 1;
            &:hover {
                &::after {
                    position: absolute;
                    content: "";
                    background: rgba($darkgray, 0.4);
                    width: calc(100% + 10px);
                    height: 100%;
                    top: 0;
                    left: -5px;
                    border-radius: 10px;
                }
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }
    &-avatar {
        width: 40px;
        height: 40px;
        background: $darkgray;
        border-radius: 50%;
        position: relative;
        margin-right: 15px;
        position: relative;
        z-index: 1;
        img {
            object-fit: cover;
            width: 100%;
            position: inherit;
            height: 100%;
        }
        .status {
            position: absolute;
            right: -1px;
            bottom: -1px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 3px solid $white;
            background: $main;
        }
    }
    &-name {
        position: relative;
        z-index: 1;
        font-family: $golosReg;
        h4 {
            font-size: 16px;
            line-height: 19px;
            color: $maindark;
            padding-bottom: 3px;
        }
        p {
            color: $darkgray;
            font-size: 14px;
            line-height: 17px;
        }
    }
}
.avatar__image {
    overflow: hidden;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.schedule__form {
    padding-top: 30px;
    &-body {
        display: flex;
        justify-content: flex-start;
        max-width: 800px;
    }
    &-data {
        max-width: 75%;
        width: 100%;
        margin-right: 20px;
    }
    &-time {
        input[type="time"] {
            -webkit-appearance: none;
            &:focus {
                &.form__control span {
                    top: 5px;
                }
            }
        }
        input[type="time"]::-webkit-calendar-picker-indicator {
            background: none;
            display: none;
        }
    }
    &-actions {
        position: absolute;
        bottom: 0;
        z-index: 1;
        .checkbox {
            margin-bottom: 30px;
            a {
                color: $maindark;
                text-decoration: none;
            }
        }
        .button__group-btn {
            justify-content: start;
        }
    }
}
.selected__users {
    height: 100%;
    max-height: 350px;
    overflow: auto;
    @media (min-width: 1200px) and (max-width: 1560px) {
        max-height: 200px;
    }
}

.selected__user {
    &-list {
        list-style: none;
        margin-top: 20px;
        li {
            display: flex;
            padding: 5px 15px;
            align-items: center;

            &:last-child {
                border-bottom: none;
            }
        }
    }
    &-avatar {
        width: 40px;
        height: 40px;
        background: $darkgray;
        border-radius: 50%;
        position: relative;
        margin-right: 15px;
        position: relative;
        // z-index: 1;
        img {
            object-fit: cover;
            width: 100%;
            position: inherit;
            height: 100%;
        }
        .status {
            position: absolute;
            right: -1px;
            bottom: -1px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 3px solid $white;
            background: $main;
        }
    }
    &-name {
        position: relative;
        // z-index: 1;
        font-family: $golosReg;
        h4 {
            font-size: 16px;
            line-height: 19px;
            color: $maindark;
            padding-bottom: 3px;
        }
        p {
            color: $darkgray;
            font-size: 14px;
            line-height: 17px;
        }
    }
}

.remove__user {
    margin: 0 0 0 auto;
    cursor: pointer;
}
</style>