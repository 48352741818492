<template>
  <div id="app">
    <Preloader />
    <Leftbar :style="{ maxWidth: maxWidth + '%' }" />
    <div class="container" :style="{margin: routeMargin + 'px'}">
      <div class="wrapper">
        <transition name="fade">
          <router-view />
        </transition>
        <BottomMenu />
      </div>
    </div>
  </div>
</template>

<script>
import Leftbar from '@/components/Leftbar.vue'
import BottomMenu from "@/components/BottomMenu.vue";
import Preloader from './components/Preloader.vue';
export default {
  components: {
    Leftbar,
    BottomMenu,
    Preloader
  },
  data() {
    return {
      maxWidth: 50,
      routeMargin: '0 60'
    }
  },
  updated() {
    this.changeWidthPerPage()
    this.callRouteUpdate()
    
  },
  created() {
    this.changeWidthPerPage()
    this.callRouteUpdate()
  },
  methods: {
    changeWidthPerPage() {
      this.$route.path === '/' ? this.maxWidth = 50 : this.maxWidth = 25;
    },
    callRouteUpdate(){
      this.$route.path === '/join/' ? this.routeMargin = 0 : this.routeMargin = '0 60'
    }
  }
}
</script>
<style lang="scss">
@import url("./assets/fonts/fonts.css");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background: url(./assets/img/bg-bottom.svg), $almostwhite;
  background-repeat: no-repeat, no-repeat;
  background-position: bottom right;
  width: 100%;
  height: 100vh;
  font-size: 16px;
  font-family: $golosReg;
   overflow:hidden;
}

.container {
  max-width: 100%;
  margin: 0 60px;
  position: relative;
  @media (min-width: 1300px) and(max-width:1560px) {
    margin: 0 30px;
  }
}

.wrapper {
  height: 100vh;
  width: 100%;
}
.leftside {
  width: 100%;
  max-width: calc(25% - 30px);
  @media (min-width: 1300px) and(max-width:1560px) {
    max-width: calc(25% - 15px);
  }
}
.rightside {
  width: 100%;
  // max-width: 75%;

}
.leftside__text {
  max-width: 700px;
  h1 {
    font-family: $golosBlack;
    font-size: 48px;
    line-height: 70px;
    color: $white;
    letter-spacing: 0.04em;
    padding-bottom: 30px;
    span {
      background: $white;
      color: $main;
      border-radius: 6px;
      padding: 5px 10px;
    }
  }
}
.page__description {
  color: $white;
  font-size: 24px;
  line-height: 34px;
  font-family: $golosReg;
}
.page__title {
  font-size: 36px;
  line-height: 40px;
  font-family: $golosBold;
  color: $maindark;
  padding-bottom: 30px;
    @media (min-width: 1300px) and(max-width:1560px) {
    padding-bottom: 15px;
  }
}
.page__text {
  font-size: 18px;
  color: $darkgray;
  line-height: 28px;
  font-family: $golosReg;
   padding-bottom: 60px;
    @media (min-width: 1300px) and(max-width:1560px) {
    padding-bottom: 15px;
  }
}
.actions__form {
  max-width: 520px;
  padding-bottom: 60px;
  @media (min-width: 1400px) and(max-width:1560px) {
    padding-bottom: 30px;
  }
}
.additional__actions {
  p {
    font-size: 16px;
    line-height: 24px;
    color: $darkgray;
    a {
      color: $maindark;
      text-decoration: none;
      font-family: $golosMedium;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.default--bg {
  background: $main;
  &:hover {
    background: $brightmain;
  }
  &:active {
    background: $darkmain;
  }
}
.warning {
  background: $warning;
  &:hover {
    background: #ff7592;
  }
}
.links {
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  color: $darkgray;
  &:hover {
    text-decoration: underline;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
