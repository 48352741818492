<template>
    <div class="page__header">
        <div class="page__user">
            <div class="page__user-avatar" v-if="page !== 'Schedule'">
                <img src="../assets/img/avatar.png" alt />
                <div class="page__user-edit">
                    <img src="../assets/icons/change-avatar.svg" alt />
                </div>
                <div class="page__user-status"></div>
            </div>
            <div class="page__user-name" v-if="page !== 'Schedule'">
                <p>
                    Hello,
                    <span>{{ username }}</span>!
                </p>
            </div>
            <slot></slot>
        </div>
        <div class="page__actions">
            <div class="page__actions-buttons">
                <div class="page__actions-notification">
                    <div
                        class="page__actions-button default--bg"
                        @click.stop="showNotificationList"
                    >
                        <img src="../assets/icons/notification.svg" alt />
                    </div>
                    <div class="incoming__notifications" v-if="notification !== 0">
                        <p>{{ notification }}</p>
                    </div>
                    <div class="incoming__notifications-list" v-if="showNotification">
                        <ul>
                            <li v-for="item in notifications" :key="item">
                                <div class="notification__icon">
                                    <img
                                        src="../assets/icons/missed-call.svg"
                                        alt
                                        v-if="item.type === 'call'"
                                    />
                                    <img
                                        src="../assets/icons/meeting-invitation.svg"
                                        alt
                                        v-if="item.type === 'meeting'"
                                    />
                                </div>
                                <div class="notification__details">
                                    <p>{{ item.title }}</p>
                                    <span>{{ item.date }}</span>
                                </div>
                            </li>
                        </ul>
                        <router-link
                            to="/all-notifications"
                            class="incoming__notifications-button"
                        >Show all</router-link>
                    </div>
                </div>
                <div class="page__actions-log">
                    <div class="page__actions-button warning">
                        <img src="../assets/icons/logout.svg" alt />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['page'],
    data() {
        return {
            username: "John Doe",
            notification: '',
            showNotification: false,
            notifications: [
                {
                    type: 'call',
                    date: 'Nov 11 in 15:40',
                    title: 'Missed Call'
                },
                {
                    type: 'call',
                    date: 'Nov 12 in 16:54',
                    title: 'Missed Call'
                },
                {
                    type: 'meeting',
                    date: 'Nov 13 in 10:30',
                    title: 'Missed Call'
                },

            ]
        };
    },
    mounted() {
        this.countNotifications();
    },
    methods: {
        countNotifications() {
            this.notification = this.notifications.length
        },
        showNotificationList() {
            this.showNotification = !this.showNotification
            // TODO: Make closing notification outside button!!!
        },
    }
};
</script>

<style lang="scss">
.page {
    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 70px;
    }
    &__user {
        display: flex;
        align-items: center;
        &-avatar {
            margin-right: 20px;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $darkgray;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
            &:hover {
                .page__user-edit {
                    opacity: 1;
                }
            }
        }
        &-edit {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba($main, 0.7);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: 0.3s all ease-in-out;
            cursor: pointer;
            img {
                width: 30px;
                height: 30px;
                object-fit: contain;
            }
        }
        &-name {
            p {
                font-size: 24px;
                font-family: $golosBold;
                color: $maindark;
                span {
                    color: $main;
                }
            }
        }
        &-status {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 3px solid $white;
            background: $main;
            position: absolute;
            bottom: -2px;
            right: 3px;
        }
    }
    &__actions {
        &-notification {
            position: relative;
        }
        &-buttons {
            display: flex;
        }
        &-button {
            border-radius: 10px;
            width: 46px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-left: 15px;
        }
    }
}
.incoming__notifications {
    width: 18px;
    height: 18px;
    background: $warning;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    font-family: $golosBold;
    p {
        font-family: $golosBold;
        color: $white;
        font-size: 12px;
        line-height: 12px;
    }
    &-list {
        width: 250px;
        border-radius: 10px;
        background: $white;
        padding: 20px;
        position: absolute;
        top: 50px;
        right: 0;
        box-shadow: 0px 8px 14px rgba(3, 48, 79, 0.1);
        transition: 0.5s all ease-in-out;
        ul {
            list-style: none;
            li {
                display: flex;
                align-items: center;
                border-bottom: 1px solid $darkgray;
                padding: 15px 10px;
                &:last-child {
                    border-bottom: none;
                }
                img {
                    margin-right: 15px;
                }
            }
        }
    }
}
.notification__icon {
    display: flex;
}
.notification__details {
    p {
        font-size: 14px;
        line-height: 17px;
        color: $maindark;
        font-family: $golosBlack;
    }
    span {
        font-size: 12px;
        line-height: 14px;
        color: $darkgray;
    }
}
.incoming__notifications-button {
    width: calc(100% + 40px);
    padding: 10px 0;
    text-align: center;
    background: $main;
    border-radius: 0 0 7px 7px;
    color: white;
    display: block;
    margin-left: -20px;
    margin-bottom: -20px;
    font-size: 16px;
    text-decoration: none;
    line-height: 19px;
    &:hover {
        background: $brightmain;
    }
    &:active {
        background: $darkmain;
    }
}
</style>