<template>
  <div class="signup">
    <div class="leftside">
      <Topbar />
      <div class="leftside__text">
        <h1>
          Welcome to
          <span>VisioRoom!</span>
        </h1>
        <p class="page__description">
          The service of free
          <br />personal and group calls.
        </p>
      </div>
    </div>
    <div class="rightside">
      <h2 class="page__title">Welcome!</h2>
      <p class="page__text">We are glad to see you with us.</p>
      <form action class="form actions__form">
        <FormInput placeholderText="Your name" inputType="text" className="input rounded--top" />
        <FormInput placeholderText="Email Address" inputType="email" className="input" />
        <FormInput placeholderText="Password" inputType="password" inputIcon="hidden-pass.svg" className="input"
        />
        <FormInput
          placeholderText="Repeat password"
          inputType="password"
          inputIcon="hidden-pass.svg"
          className="input rounded--bottom"
        />
        <div class="form__actions">
          <Checkbox checkboxText="I agree all statement in" assignLabel="agree">
            <router-link to="/terms">&nbsp;Terms & Conditions</router-link>
          </Checkbox>
          <Checkbox checkboxText="Remember me" assignLabel="remember" />
        </div>
        <div class="form__buttons">
          <Button
            btnClass="btn btn--medium btn--default"
            btnType="button"
            btnName="Sign Up"
            type="submit"
          />
          <Button
            btnClass="btn btn--medium btn--bordered"
            link="https://www.google.com"
            btnName="Google"
            btnIcon="google-icon.svg"
          />
        </div>
      </form>
      <div class="additional__actions">
        <p>
          Already have an account?
          <router-link to="/login">Log In</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from '../components/FormInput.vue';
import Checkbox from '../components/Checkbox.vue';
import Button from '../components/Button.vue';
import Topbar from '@/components/Topbar.vue';
export default {
  components: { FormInput, Checkbox, Button, Topbar },
  props: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.signup {
  height: 100%;
  position: relative;
  display: flex;

  .rightside {
    max-width: 75%;
    width: 100%;
    padding-top: 60px;
    padding-left: 140px;
    
    
    .form__actions {
      flex-direction: column;
      .checkbox {
        margin-bottom: 15px;
      }
    }
  }
  .form__actions {
    display: flex;
    justify-content: space-between;
    max-width: 520px;
    margin-top: 30px;
    padding-bottom: 60px;
    @media (min-width: 1400px) and(max-width:1560px) {
      padding-bottom: 30px;
    }
    a{
      color:$maindark;
      font-size: 16px;
      text-decoration: none;
    }
  }
}
.form__buttons {
  display: flex;
  .button__group-btn {
    justify-content: flex-start;
    margin: 0 auto;
  }
}
</style>