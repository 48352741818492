<template>
    <div class="checkbox">
        <label :for="assignLabel">
            <input type="checkbox" :id="assignLabel" />
            <span class="checkmark"></span>
            {{ checkboxText }}
            <slot></slot>
        </label>
    </div>
</template>

<script>
export default {
    props: ['checkboxText', 'assignLabel'],
    data() {
        return {};
    },
};
</script>

<style lang="scss">
.checkbox {
    label {
        color: $darkgray;
        font-size: 16px;
        font-family: $golosReg;
        cursor: pointer;
        height: 24px;
        display: flex;
        align-items: center;
    }

    display: block;
    position: relative;
    padding-left: 35px;

    cursor: pointer;
    user-select: none;
    cursor: pointer;
}
.checkbox input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: $white;
    border: 1px solid $middlegray;
    border-radius: 6px;
}

.checkbox:hover input ~ .checkmark {
    background-color: $white;
    border: 2px solid $brightmain;
}

.checkbox input:checked ~ .checkmark {
    background-color: $brightmain;
    border: none;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked ~ .checkmark:after {
    display: block;
}

.checkbox .checkmark:after {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 14px;
    background-image: url("../assets/icons/check.svg");
    background-repeat: no-repeat;
    background-size: contain;
}
</style>