<template>
  <div class="wrapper">About</div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
};
</script>

<style lang="">
</style>