<template>
    <div class="dashboard">
        <Leftside />
        <div class="rightside">
            <PageHeader/>
            <div class="dashboard__body">
                <div class="dashboard__body-actions">
                    <div class="dashboard__body-action meeting--new">
                        <div class="dashboard__body-icon">
                            <img src="../assets/icons/new-meeting.svg" alt />
                        </div>
                        <div class="dashboard__body-card">
                            <p>
                                New
                                <br />meeting
                            </p>
                            <span>
                                Creat link to your right
                                <br />now video call
                            </span>
                            <Button
                                btnType="button"
                                btnName="Start now"
                                btnClass="btn btn--medium btn--default"
                                @btnAction="openMeetingModal"
                            />
                        </div>
                    </div>
                    <div class="dashboard__body-action meeting--schedule">
                        <div class="dashboard__body-icon">
                            <img src="../assets/icons/schedule-meeting.svg" alt />
                        </div>
                        <div class="dashboard__body-card">
                            <p>
                                Schedule
                                <br />a meeting
                            </p>
                            <span>
                                Schedule a meeting with
                                <br />your teammates
                            </span>
                            <Button
                                btnType="routerLink"
                                link="/schedule"
                                btnName="Create"
                                btnClass="btn btn--medium btn--default"
                                @btnAction="openMeetingModal"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="modalVisible" @close-modal="closeModal">
            <img src="../assets/img/modal-generate-link.svg" alt />
            <p>Link to your meeting</p>
            <span>
                Copy this link and share with those you want <br>
                to invite. Save it if you are planning a meeting later.
            </span>
            <FormInput inputType='text' className="input rounded input--small link--input" readonlyAttr="true" :placeholderText='generatedLink'>
                <Button :btnName="btnNameAction" btnType="button"  btnClass="btn btn--medium btn--default copy--btn" @btnAction='copyUrlButton'/>
            </FormInput>
        </Modal>
    </div>
</template>

<script>
import Button from "../components/Button.vue";
import Modal from "../components/Modal.vue";
import FormInput from "../components/FormInput.vue";
import Leftside from "../components/Leftside.vue";
import PageHeader from "../components/PageHeader.vue";
export default {
    props: {},
    data() {
        return {
            dashboard: true,
            modalVisible: false,
            generatedLink: '',
            btnNameAction: 'Copy',
        };
    },
    components: {Leftside, Button, Modal, FormInput,PageHeader },

    computed: {

    },
    methods: {
        openMeetingModal() {
            this.modalVisible = true      
             let randomSymbols = Math.random().toString(36).slice(9) + '-' + Math.random().toString(36).slice(9) + '-' + Math.random().toString(36).slice(8);
             this.generatedLink = 'meet.visioroom.com/' + randomSymbols      
        },
        closeModal() {
            this.modalVisible = false
        },
        copyUrlButton(){
            navigator.clipboard.writeText(this.generatedLink)
            this.btnNameAction = 'Copied!'
            setTimeout(()=>{
                this.btnNameAction = 'Copy'
            },3000)
        },
       
        
    }
};
</script>

<style lang="scss">
.dashboard {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
   
    .rightside {
        padding-left: 70px;
        padding-top: 45px;
        position: relative;
    }
   
}
.nav {
    margin-top: -30px;
    &__list {
        list-style: none;
        li {
            position: relative;
            &::before {
                transition: 0.3s all ease;
                position: absolute;
                content: "";
                left: -30px;
                width: calc(100% + 30px);
                height: 46px;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 10px 0px 0px 10px;
            }
            &:hover {
                &::before {
                    background: $main;
                }
            }
            a {
                text-decoration: none;
                color: $white;
                font-family: $golosReg;
                font-size: 16px;
                display: flex;
                align-items: center;
                padding: 15px 0;
                position: relative;
                z-index: 1;
                img {
                    margin-right: 30px;
                }
            }
        }
    }
}

.dashboard__body {
    height: calc(100% - 70px);
    &-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    &-action {
        margin: 0 15px;
    }
    &-icon {
        width: 80px;
        height: 80px;
        background: rgba($main, 0.5);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -40px auto;
        z-index: 1;
        position: relative;
    }
    &-card {
        width: 310px;
        box-shadow: 0px 8px 14px rgba(3, 48, 79, 0.1);
        border-radius: 30px;
        background: $white;
        padding: 100px 60px 30px;
        text-align: center;
        p {
            font-size: 36px;
            line-height: 43px;
            color: $maindark;
            font-family: $golosBold;
            padding-bottom: 30px;
        }
        span {
            font-size: 16px;
            line-height: 22px;
            color: $darkgray;
            font-family: $golosReg;
            padding-bottom: 65px;
            display: block;
        }
        .button__group-btn {
            margin: 0;
        }
    }
}
.link--input{
    min-width:520px;
}
.modal{
    .form__control span{
        top:25px;
    }
    .button__group-btn {
            margin: 0;
        }
}
.copy--btn{
     position: absolute;
    top: 10px;
    right: 10px;
    // transform: translateY(-50%);
    width:150px;
    height:55px;
}
</style>