<template>
    <div class="discussion">
        <div class="discussion__grid">
            <!-- <div class="discussion__grid-user" v-for="(user,index) in usersConnected" :key="index"> -->
            <!-- <video ref="video" autoplay></video> -->
            <!-- <img src="../assets/img/avatar.png" alt=""> -->
            <!-- </div> -->
            <!-- <span>{{ usersOnline }}</span> -->
            <!-- <span v-for="mes in messages" :key="mes">1{{ mes }}</span> -->
        </div>
        <div class="discussion__chat" v-if="showChat">
            <div class="discussion__chat-header">
                <p>Group chat</p>

                <img src="../assets/icons/close.svg" alt @click="closeChatBox" />
            </div>
            <div class="discussion__chat-notify" v-if="notifyVisible">
                <img src="../assets/icons/info.svg" alt />
                <p>
                    When the video call ends,
                    <br />the messages will be deleted!
                </p>
                <img src="../assets/icons/close.svg" alt @click="hideNotify" />
            </div>
            <div class="discussion__chat-messages" ref="messageBox">
                <div
                    :class="{ own: message.type }"
                    class="discussion__chat-message"
                    v-for="message in messages"
                    :key="message"
                >
                    <!-- <div class="chat__message-avatar">
                        <img :src="require('@/assets/img/' + message.userAvatar + '')" alt />
                    </div>-->
                    <div class="chat__message-message">
                        <div class="chat__message-header">
                            <span>{{ message.user }}</span>
                            <span>{{ message.time }}</span>
                        </div>
                        <div class="chat__message-body">
                            <ul>
                                <li>
                                    <p>{{ message.message }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <small v-if="typing" class="text-white">{{ typing }} is typing</small>
            </div>
            <div class="discussion__chat-input">
                <div class="form__control">
                    <input
                        type="text"
                        @keydown.enter="sendMessage"
                        class="input input--small rounded"
                        placeholder="Type a message..."
                        v-model="chatmessage"
                    />
                    <button @click.prevent="sendMessage">
                        <img src="../assets/icons/send.svg" alt />
                    </button>
                </div>
            </div>
        </div>
        <div class="discussion__chat-actions">
            <div class="discussion__chat-time">
                <span></span>
                <p>{{ duration }}</p>
            </div>
            <div class="discussion__chat-btns">
                <Button btnType="button" btnClass="btn btn--default" btnIcon="expand.svg" />
                <Button btnType="button" btnClass="btn btn--default" btnIcon="microphone.svg" />
                <Button
                    btnType="button"
                    btnClass="btn btn--default end"
                    btnIcon="endcall.svg"
                    @btnAction="showChatBox"
                />
                <Button btnType="button" btnClass="btn btn--default" btnIcon="video.svg" />
                <Button btnType="button" btnClass="btn btn--default" btnIcon="sound.svg" />
            </div>
            <div class="discussion__chat-show" @click="showChatBox">
                <img src="../assets/icons/chat-btn.svg" alt />
            </div>
        </div>
    </div>
</template>

<script>
// import io from "socket.io-client";
import Button from "../components/Button.vue";
export default {
    props: {},
    data() {
        return {
            showChat: false,
            notifyVisible: true,
            myVideoStream: null,
            usersOnline: [],
            messages: [],
            chatmessage: null,
            duration: 0,
            video: document.createElement('video'),
            typing: false,
            newMessage: null,
            usersConnected: [
                // {
                //     userAvatar: 'avatar.png',
                //     userName: 'Ruta Kaira',
                //     date: new Date().getHours() + ':' + new Date().getMinutes(),
                //     message: 'I sent you a document by mail, please check',
                //     own: true
                // },
                // {
                //     userAvatar: 'avatar.png',
                //     userName: 'Lina McGreal',
                //     date: new Date().getHours() + ':' + new Date().getMinutes(),
                //     message: 'Ok, thanks'
                // },


            ]
        };
    },
    components: { Button },
    // mounted() {



    // },

    methods: {
        showChatBox() {
            this.showChat = true;
        },
        closeChatBox() {
            this.showChat = false;
        },
        hideNotify() {
            this.notifyVisible = false;
        },
        userTyping() {
            this.$socket.emit('typing',)
        },
        sendMessage() {
            if (this.chatmessage !== '') {
                this.$socket.emit('chat-message', {
                    message: this.chatmessage,
                    user: this.$socket.id,
                    type: 0,
                    time: new Date().getHours() + ':' + new Date().getMinutes()

                })
            }
            this.chatmessage = ''
        },
        getUserVideo() {
            navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                .then((mediaStream) => {
                    this.$socket.emit('stream', mediaStream);
                })

        },
        // addVideoStream(video, stream) {
        //     video.srcObject = stream;
        //     video.addEventListener("loadedmetadata", () => {
        //         video.play();
        //         let videoGrid = document.querySelector('.discussion__grid')
        //         videoGrid.append(video);
        //     });
        // },
        // endStream() {
        //     window.localStream.getVideoTracks()[0].stop();
        //     window.localStream.getAudioTracks()[0].stop();

        //     // this.$refs.video.remove()
        //     // this.$refs.video.src = ''
        //     this.$router.push('/dashboard')
        // },
        discussionDuration() {
            let hours = 0;
            let minutes = 0;
            let seconds = 0;
            setInterval(() => {
                seconds++
                if (seconds == 60) {
                    seconds = 0
                    minutes++
                    if (minutes == 60) {
                        minutes = 0
                        hours++
                        if (hours == 24) {
                            return
                        }
                    }

                }
                this.duration = (minutes < 10 ? '0' + minutes : minutes) + ' : ' + (seconds < 10 ? '0' + seconds : seconds)
                if (minutes == 60) {
                    this.duration = hours + ':' + minutes + ' : ' + seconds
                }
            }, 1000)

        }
    },
    created() {
        this.discussionDuration()
        this.getUserVideo()
        this.$socket.on('typing', (data) => {
            this.typing = data;
        });


        this.$socket.on('stopTyping', () => {
            this.typing = false;
        });
    },
    mounted() {
        // this.usersOnline.push(this.$socket.id)
        // console.log(this.$socket)
        this.$socket.on('chat-message', data => {
            this.messages.push(data)
            this.$nextTick(() => {
                let messageBox = document.querySelector('.discussion__chat-messages')
                messageBox.scrollTop = messageBox.scrollHeight;
            })
        })
        this.$socket.on('stream', (data) => {

            // const video = document.createElement('video');
            // let cont = document.querySelector('.discussion__grid')

            // video.setAttribute('autoplay','')
            // video.setAttribute('ref','video')
            // video.setAttribute('src', data)
            console.log(data)
            // cont.append(video)
        })
    },
    watch: {
        chatmessage(value) {
            value ? this.$socket.emit('typing', this.$socket.id) : this.$socket.emit('stopTyping')
        }
    },
};
</script>

<style lang="scss">
@mixin max-cols($max-cols, $cols-min-width, $grid-column-gap) {
    grid-template-columns: repeat(
        auto-fit,
        minmax(
            min(
                max(
                    (
                        100%/ (#{$max-cols} + 1) - #{$grid-column-gap}*#{$max-cols}/
                            (#{$max-cols} + 1) + 1px
                    ),
                    #{$cols-min-width}
                ),
                100%
            ),
            1fr
        )
    );
}

.discussion {
    background: $maindark;
    width: 100%;
    height: 100%;
    &__grid {
        display: grid;
        padding: 10px;
        height: 100%;
        --max-cols: 3;
        --cols-min-width: 10rem;
        --grid-row-gap: 5px;
        --grid-column-gap: 5px;
        gap: var(--grid-row-gap) var(--grid-column-gap);
        @include max-cols(
            var(--max-cols),
            var(--cols-min-width),
            var(--grid-column-gap)
        );
        &-user {
            border-radius: 10px;
            min-height: 100px;
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
                background: red;
            }
        }
    }
    &__chat {
        top: 0;
        position: absolute;
        right: 10px;
        z-index: 12;
        width: 100%;
        max-width: 355px;
        height: calc(100vh - 20px);
        border-radius: 30px;
        background: $brigthwhite;
        padding: 20px;
        margin-top: 10px;
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 10px;
            padding-bottom: 20px;
            border-bottom: 1px solid $darkgray;
            p {
                color: $maindark;
                font-size: 18px;
                line-height: 22px;
                font-family: $golosBold;
                padding-left: 10px;
            }
            img {
                cursor: pointer;
                margin-right: 15px;
            }
        }
        &-messages {
            overflow-y: auto;
            height: 64%;
        }
        &-message {
            margin: 10px 0;
            display: flex;
            flex-direction: row-reverse;
            &.own {
                flex-direction: row;
            }
        }
        &-notify {
            background: $brightgray;
            padding: 15px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            margin: 20px 0;
            p {
                font-size: 14px;
                font-family: $golosReg;
                color: $maindark;
                padding-left: 10px;
            }
            img {
                &:first-child {
                    margin: 0 0 auto 0;
                }
                &:last-child {
                    opacity: 0.5;
                    cursor: pointer;
                    margin: 0 0 0 auto;
                }
            }
        }
        &-actions {
            position: absolute;
            bottom: 20px;
            z-index: 9;
            width: 100%;
            padding: 0 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &-btns {
            display: flex;
            align-items: center;
            justify-content: center;
            .end {
                width: 70px !important;
                height: 70px !important;
                background: $warning;
            }
            .button__group-btn {
                margin: 0;
            }
            .btn {
                width: 46px;
                height: 46px;
                margin: 0 10px;
                img {
                    margin-right: 0;
                }
            }
        }
        &-time {
            background: rgba($maindark, 0.5);
            border-radius: 10px;
            padding: 10px 15px;
            display: flex;
            align-items: center;
            p {
                color: white;
                font-size: 16px;
            }
            span {
                width: 8px;
                display: block;
                height: 8px;
                border-radius: 50%;
                background: $warning;
                margin-right: 10px;
            }
        }
        &-show {
            width: 46px;
            height: 46px;
            background: $main;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        &-input {
            position: absolute;
            bottom: 20px;
            width: calc(100% - 40px);
            input {
                padding: 14px 50px 14px 14px;
                font-size: 16px;
                height: 62px;
                box-shadow: 0px 8px 14px rgba(3, 48, 79, 0.1);
            }
            button {
                width: 46px;
                height: 46px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                border: none;
                background: $main;
                justify-content: center;
                cursor: pointer;
                position: absolute;
                right: 8px;
                top: 8px;
                img {
                    right: 0;
                    position: initial;
                }
            }
        }
    }
}
.own {
    > .chat__message-avatar {
        margin-left: 0;
        margin-right: 10px;
    }
}
.chat__message {
    &-header {
        display: flex;
        justify-content: space-between;
        span {
            color: $darkgray;
            font-size: 14px;
            font-family: $golosReg;
        }
    }
    &-avatar {
        width: 100%;
        max-width: 45px;
        height: 45px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-right: 0;
        margin-left: 10px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    &-message {
        background: $white;
        padding: 14px;
        border-radius: 10px;
        min-width: 190px;
        p {
            color: $maindark;
            font-size: 16px;
            padding-top: 5px;
        }
    }
}
</style>