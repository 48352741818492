<template>
    <div class="button__group-btn">
        <button :class="btnClass" v-if="btnType === 'button'" :type="type" @click.prevent='buttonAction'>
            <img :src="require('@/assets/icons/' + btnIcon + '')" alt  v-if="btnIcon"/>
            {{ btnName }}
        </button>
        <router-link :to="link" :class="btnClass" v-else-if="btnType === 'routerLink'" >
            <img :src="require('@/assets/icons/' + btnIcon + '')" alt  v-if="btnIcon"/>
            {{ btnName }}
        </router-link>
        <a :href="link" :class="btnClass" target="_blank" v-else>
            <img :src="require('@/assets/icons/' + btnIcon + '')" alt  v-if="btnIcon"/>
            {{ btnName }}
        </a>
    </div>
</template>

<script>
export default {
    props: ['btnName', 'btnIcon', 'type', 'link', 'btnClass', 'btnType'],
    data() {
        return {};
    },
    methods:{
        buttonAction(){
            this.$emit('btnAction')
        }
    }
};
</script>

<style lang="scss">
.button__group-btn {
    margin: 0 auto 30px auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn {
    border: none;
    border-radius: 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 24px;
    font-family: $golosMedium;
    line-height: 29px;
    box-shadow: 0px 14px 30px rgba($maindark, 0.2);
    width: 100%;
    max-width: 300px;
    height: 60px;
    transition: 0.3s all ease;
    cursor: pointer;
    img {
        margin-right: 6px;
        transition: 0.3s all ease;
    }
    &:hover {
        background: $brightmain;
        box-shadow: 0px 14px 30px rgba($maindark, 0.2);
    }
    &:active {
        background: $darkmain;
    }
      &--large{
         max-width: 300px;
    height: 60px;
    }
    &--medium{
            max-width: 190px;
    height: 42px;
    font-size: 18px;
    }
    &--default {
        background: $main;
    }
    &--bordered {
        border: 1px solid $main;
        color: $main;
        box-shadow: none;
        &:hover {
            color: $white;
            border-color: $brightmain;
            box-shadow: none;
            img {
                filter: invert(1) sepia(188%) saturate(157%) hue-rotate(0deg)
                    brightness(224%) contrast(179%);
            }
        }
        &:active {
            color: $white;
            border-color: $darkmain;
        }
    }
}
.disabled{
    background: $darkgray;
    pointer-events: none;
}
</style>