<template>
  <div class="form__control">
    <input
      :type="inputType"
      :class="className"
      :readonly="readonlyAttr"
      :name="inputName"
      :value="inputValue"
      :maxlength="inputLength"
      :pattern="inputPattern"
      autocomplete="off"
      v-model="checkValue"
      :placeholder='placeholders'
      @input="inputEmptyCheck();$emit('inputValue', $event.target.value);"
      @click="$emit('showList')"
    />
    <span
      :style='{"top" : moveSpan, "font-size": keepFontSize}'
      v-if="placeholderText"
    >{{ placeholderText }}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: [
  'inputType', 
  'inputName', 
  'inputValue', 
  'inputIcon', 
  'inputLength',
  'inputPattern',
  'placeholderText', 
  'className', 
  'readonlyAttr',
  'placeholders'
  ],
  data() {
    return {
      moveSpan: '',
      keepFontSize: '',
      checkValue: ''
    };
  },
  methods: {
    inputEmptyCheck() {
      if (this.checkValue !== '') {
        this.moveSpan = '5px'
        this.keepFontSize = '14px'
      } else {
        this.moveSpan = '30px'
        this.keepFontSize = '18px'
      }
    },
  },
  openList(){
    this.$emit('openList')
  }


};
</script>

<style lang="scss">
.form__control {
  position: relative;

  span {
    position: absolute;
    left: 30px;
    top: 30px;
    font-size: 18px;
    // line-height:18px;
    pointer-events: none;
    color: $darkgray;
    transition: 0.3s all ease;
  }
  img {
    position: absolute;
    right: 30px;
  }
}
.input {
  background: $white;
  border: 1px solid $middlegray;
  box-sizing: border-box;
  padding: 30px;
  width: 100%;
  max-width: 520px;
  height: 80px;
  outline: none;
  font-size: 18px;
  line-height: 22px;
  font-family: $golosReg;

  &:hover {
    & + span {
      color: $maindark;
    }
  }
  &:focus {
    border-color: $maindark;
    // & + span {
    //   top: 5px;
    //   font-size: 14px;
    //   line-height: 17px;
    //   color: $maindark;
    // }
  }
}
.rounded--top {
  border-radius: 10px 10px 0px 0px;
}
.rounded--bottom {
  border-radius: 0px 0px 10px 10px;
}
.rounded {
  border-radius: 10px;
}
.input--small {
  height: 75px;
}
</style>