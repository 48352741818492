<template>
    <transition name="fade">
        <div class="loader" v-if="visible">
            <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
    </transition>
</template>

<script>
export default {
    props: {},
    data() {
        return {
            visible: true
        };
    },
    mounted() {
        this.togglePreloader()
    },
    methods: {
        togglePreloader() {
            setTimeout(() => {
                this.visible = false
            }, 1000)
        }
    }
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.loader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: url(../assets/img/bg-bottom.svg), $brigthwhite;
    background-repeat: no-repeat, no-repeat;
    background-position: bottom right;
    z-index: 9;
    ul {
        display: flex;
        list-style: none;
        width: auto;
        height: 150px;
        li {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin: 5px;
            background: #26bdb4;
            &:nth-child(1) {
                animation: color1 ease-in-out infinite 1.1s forwards,
                    jump ease-in infinite 1.1s forwards;
            }
            &:nth-child(2) {
                animation: color2 ease-in-out infinite 1.1s forwards,
                    jump ease-in infinite 1.1s forwards;
                animation-delay: 0.2s;
            }
            &:nth-child(3) {
                animation: color3 ease-in-out infinite 1.1s forwards,
                    jump ease-in infinite 1.1s forwards;
                animation-delay: 0.3s;
            }
            &:nth-child(4) {
                animation: color4 ease-in-out infinite 1.1s forwards,
                    jump ease-in infinite 1.1s forwards;
                animation-delay: 0.4s;
            }
        }
    }
}

@keyframes jump {
    0% {
        transform: translateY(0);
        height: 15px;
        border-radius: 50%;
    }
    40% {
        transform: translateY(0);
        height: 15px;
        border-radius: 50%;
    }
    50% {
        transform: translateY(-60px);
        height: 40px;
        border-radius: 8px;
    }
    80% {
        transform: translateY(-5px);
    }
    85% {
        transform: translateY(5px);
    }
    90% {
        transform: translateY(-2px);
    }
    95% {
        transform: translateY(2px);
    }
    100% {
        transform: translateY(0);
        height: 15px;
        border-radius: 50%;
    }
}
@keyframes color1 {
    40% {
        background: #26bdb4;
    }
    50% {
        background: #ee4266;
    }
    70% {
        background: #ee4266;
    }
}
@keyframes color2 {
    40% {
        background: #26bdb4;
    }
    50% {
        background: #ffbf3f;
    }
    70% {
        background: #ffbf3f;
    }
}
@keyframes color3 {
    40% {
        background: #26bdb4;
    }
    50% {
        background: #03304f;
    }
    70% {
        background: #03304f;
    }
}
@keyframes color4 {
    40% {
        background: #26bdb4;
    }
    50% {
        background: #00965e;
    }
    70% {
        background: #00965e;
    }
}
</style>