<template>
    <div class="leftbar" v-if="$route.name !== 'Call' && $route.name !== '404'">
        <div class="leftbar__circle"></div>
        <div class="leftbar__circle"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },
    methods: {

    },
};
</script>

<style lang="scss">
.leftbar {
    &:after {
        position: absolute;
        content: "";
        background: url(../assets/img/noise-bg.svg);
        background-repeat: no-repeat;
        background-position: bottom;
        mix-blend-mode: screen;
        background-size: cover;
        width: 100%;
        height: 30%;
        bottom: 0;
    }
    transition: 0.4s ease-in-out all;
    max-width: 50%;
    background: linear-gradient(180deg, #26bdb4 49.44%, #57e1bd 96.91%);
    backdrop-filter: blur(129px);
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    left: 0;
    &__circle {
        position: absolute;
        &:nth-child(1) {
            animation: moveCircleTL 4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
                alternate infinite;
            background: #57e1bd;
            filter: blur(60px);
            width: 100%;
            max-width: 430px;
            height: 430px;
            border-radius: 50%;
            right: 0;
            top: 0;
        }
        &:nth-child(2) {
            background: #57e1bd;
            filter: blur(60px);
            animation: moveCircleBR 4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
                alternate infinite;
            width: 100%;
            max-width: 270px;
            height: 270px;
            border-radius: 50%;
            left: -10%;
            top: 30%;
        }
    }
}
@keyframes moveCircleTL {
    0% {
        transform: scale(0.7) translateY(-250px) translateX(250px);
    }
    100% {
        transform: scale(1) translateY(-100px) translateX(0px);
    }
}
@keyframes moveCircleBR {
    0% {
        transform: scale(0.7) translateY(250px) translateX(0);
    }
    100% {
        transform: scale(1) translateY(0) translateX(250px);
    }
}
</style>