<template>
  <div class="home">
    <div class="leftside">
      <TopBar />
      <div class="leftside__text">
        <h1>
          THE MOST CONVENIENT
          AND
          <span>SIMPLE VIDEO CALL</span>
          SERVICE
        </h1>
      </div>
      <div class="chips">
        <ul class="chips__list">
          <li class="chips__list-item">Your personal room</li>
          <li class="chips__list-item">Schedule</li>
          <li class="chips__list-item">Room with your team</li>
          <li class="chips__list-item">Add calendar</li>
          <li class="chips__list-item">Chat</li>
          <li class="chips__list-item">...</li>
        </ul>
      </div>
    </div>
    <div class="rightside">
      <div class="button__group">
        <Button
          btnName="Sign Up"
          btnClass="btn btn--default btn--large"
          link="/signup"
          btnIcon="signup.svg"
          btnType="routerLink"
        />
        <Button
          btnName="Log In"
          btnClass="btn btn--bordered btn--large"
          link="/login"
          btnIcon="login.svg"
          btnType="routerLink"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../components/Button.vue";
import TopBar from "@/components/Topbar.vue";
export default {
  components: {
    Button,
    TopBar
  },
  props: {

  },
  data() {
    return {
      home: true
    };
  },
  methods: {

  }

};
</script>

<style lang="scss">
.home {
  height: 100%;
  position: relative;
  display: flex;
  .leftside {
    width: 100%;
    max-width: 50%;
    
  }
  .rightside {
    width: 100%;
    max-width: 50%;
    .button__group {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      justify-content: center;
    }
  }
  .chips {
    max-width: 600px;
    padding-top: 30px;
    &__list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      &-item {
        border: 1px solid $white;
        color: $white;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        padding: 10px 15px;
        border-radius: 40px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>