<template>
  <div class="leftside" v-if="$route.name !== 'Call' && $route.name !== '404'">
            <Topbar />
            <nav class="nav">
                <ul class="nav__list">
                    <li>
                        <router-link to="/dashboard">
                            <img src="../assets/icons/squares.svg" alt />
                            Main menu
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/contacts">
                            <img src="../assets/icons/contacts.svg" alt />
                            Your contacts
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="'/join/' +callid">
                            <img src="../assets/icons/clock.svg" alt />
                            History
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/settings">
                            <img src="../assets/icons/settings.svg" alt />
                            Settings
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/terms">
                            <img src="../assets/icons/terms.svg" alt />
                            Terms & Conditions
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/faq">
                            <img src="../assets/icons/faq.svg" alt />
                            FAQ
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/contact">
                            <img src="../assets/icons/contact-us.svg" alt />
                            Contact us
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/about">
                            <img src="../assets/icons/about.svg" alt />
                            About us
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
</template>

<script>
import Topbar from "@/components/Topbar.vue"
export default {
  props: {},
  components:{
      Topbar
  },
  data() {
    return {
        callid: 'trg-ras-dds'
    };
  },
};
</script>

<style lang="">
</style>