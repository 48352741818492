<template>
  <div>
      page not found
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
};
</script>

<style lang="">
</style>