import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import io from 'socket.io-client'
// import VueSocketIO from 'vue-socket.io'

Vue.config.productionTip = false


const socket = io.connect('wss://ws.visioroom.com:8080', {
    transports: ['websocket'],
})


Vue.prototype.$socket = socket

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')