<template>
    <div class="login">
        <div class="leftside">
            <TopBar />
            <div class="leftside__text">
                <h1>
                    Welcome to
                    <span>VisioRoom!</span>
                </h1>
                <p class="page__description">
                    The service of free
                    <br />personal and group calls.
                </p>
            </div>
        </div>
        <div class="rightside">
            <h2 class="page__title">Hello</h2>
            <p class="page__text">Welcome back, please login to your account.</p>
            <form action class="form actions__form">
                <FormInput
                    placeholderText="Email Address"
                    inputType="email"
                    className="input rounded--top"
                />
                <FormInput
                    placeholderText="Password"
                    inputType="password"
                    inputIcon="hidden-pass.svg"
                    className="input rounded--bottom"
                />
                <div class="form__actions">
                    <Checkbox checkboxText="Remember me"/>
                    <a href="" class="links">Forgot password?</a>
                </div>
                <div class="form__buttons">
                    <Button btnClass="btn btn--medium btn--default" btnType="button" btnName="Log in" type="submit"/>
                    <Button btnClass='btn btn--medium btn--bordered' link="https://www.google.com" btnName="Google" btnIcon="google-icon.svg"/>
                </div>
            </form>
            <div class="additional__actions">
                <p>Don't have an account? <router-link to="/signup">Sign Up</router-link></p>
            </div>
        </div>
    </div>
</template>

<script>
import FormInput from '../components/FormInput.vue';
import Checkbox from '../components/Checkbox.vue';
import Button from '../components/Button.vue';
import TopBar from '@/components/Topbar.vue';
export default {
    components: { FormInput, Checkbox, Button, TopBar },
    props: {},
    data() {
        return {};
    },
};
</script>

<style lang="scss">
.login {
    height: 100%;
    position: relative;
    display: flex;  
    .rightside {
        max-width: 75%;
        width: 100%;
        padding-top: 100px;
        padding-left: 140px;
        .page__text{
            padding-bottom: 60px;
        }
    }
    .form__actions{
        display: flex;
        justify-content: space-between;
        max-width:520px;
        margin-top: 30px;
        padding-bottom: 60px;
    }
}
.form__buttons{
    display: flex;
    .button__group-btn{
       justify-content: flex-start;
    margin: 0 auto;
    }
}
</style>