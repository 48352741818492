<template>
    <transition name="modal-fade">
        <div class="modal__container">
            <div class="overlay" @click="closeModal"></div>
            <div class="modal">
                <div class="modal__header">
                    <div class="modal__header-close" @click="closeModal">
                        <img src="../assets/icons/close.svg" alt />
                    </div>
                </div>
                <div class="modal__body">
                    <div class="modal__body-description">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: ['modalImage', 'title', 'text'],
    data() {
        return {
        };
    },
    methods: {
        closeModal() {
            this.$emit('close-modal')
        },
    }
};
</script>

<style lang="scss">
.overlay {
    width: 100%;
    height: 100%;
    background: rgba($main, 0.4);
    top: 0;
    left: 0;
    position: fixed;
    z-index: 8;
}
.modal {
    border-radius: 30px;
    background: $white;
    padding: 40px;
    position: absolute;
    z-index: 9;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &__header {
        display: flex;
        justify-content: flex-end;
        &-close {
            cursor: pointer;
            &:hover {
                img {
                    opacity: 0.6;
                }
            }
        }
    }
    &__body {
        &-description {
            img {
                margin-top: -40px;
                margin-bottom: 60px;
            }
            text-align: center;
            p {
                font-size: 24px;
                line-height: 29px;
                color: $maindark;
                font-family: $golosBold;
                padding-bottom: 30px;
            }
            span {
                font-size: 16px;
                line-height: 22px;
                color: $darkgray;
                font-family: $golosReg;
                margin-bottom: 60px;
                display: block;
            }
        }
    }
}
.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity 0.5s ease;
}
</style>