<template>
    <div class="topbar">
        <router-link to="/">
            <img src="@/assets/img/logo.svg" alt />
        </router-link>
    </div>
</template>

<script>
export default {
    props: {},
    data() {
        return {

        };
    },
};
</script>

<style lang="scss">
.topbar {
    z-index: 1;
    width: 100%;
    padding-top: 50px;
    max-width: 300px;
    padding-bottom: 150px;
    @media (min-width: 1300px) and(max-width:1560px) {
        padding-bottom: 80px;
    }
}
</style>